<template>
    <div class="account-change-password">
        <edit :fs="userChangePasswordFields" @saved="saved" apiSave="users/password:put" customClassName="none" title="Change Password"></edit>
    </div>
</template>

<script>
import { userChangePasswordFields } from "@/config";
import Edit from "@/components/Edit";
export default {
    components: {
        Edit
    },
    computed: {
        userChangePasswordFields() {
            return userChangePasswordFields;
        }
    },
    methods: {
        saved() {
            this.$router.push("/account/login");
        }
    }
};
</script>
<style lang="less">
.account-change-password {
    min-height: 700px;
    .md-card {
        max-width: 1000px;
        margin: auto;
    }
}
</style>

